import dynamic from 'next/dynamic'
import Head from 'next/head'

import { LandingLayout } from '@/presentation/components/layouts'

const LandingPageView = dynamic(() => import('@/presentation/views/lp/lp'), {
  ssr: false
})

export default function LandingPage() {
  return (
    <>
      <Head>
        <title>skalpro ™ | home</title>
      </Head>
      <LandingPageView />
    </>
  )
}

LandingPage.getLayout = (page: React.ReactNode) => (
  <LandingLayout>{page}</LandingLayout>
)
